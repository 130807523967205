import React, { useEffect, useState } from 'react';

// mui
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

// utils
import { formatDate } from '../../utils/datetime';

// dto
import { ChecklistPayloadDto, ChecklistPayloadKeys } from '../../service/dataContract';
import { useStoreActions, useStoreState } from '../../hooks';
import { isNullOrWhitespace } from '../../utils/typeUtils';


// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        verticalContent: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        horizontalContent: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
        },
        separator: {
            marginTop: theme.spacing(3),
        },
    }),
);

export interface Props {
    readOnly: boolean,
    checklist?: ChecklistPayloadDto,
    onSave?: (checklist: ChecklistPayloadDto) => void
}

type StateType = { [key in ChecklistPayloadKeys]: any }
interface State extends StateType { }


export const ChecklistCard: React.FC<Props> = (props) => {

    const classes = useStyles();

    const { isAdmin, userName } = useStoreState(state => state.auth);

    const [changed, setChanged] = useState(false);

    const [state, setState] = useState<State>({
        smoothWheelsTurning: false,
        correctWheelsRamp: false,
        smoothMechanicsGauge: false,
        spreadingForceValue: 0,
        smoothMechanicsAlign: false,
        smoothMechanicsTop: false,
        sensorsTightening: false,
        sensorsInMeasuredRanges: false,
        testedInTrack: false,
        notes: '',
        technicianName: '',
        completed: false,
        completedDate: '',
    });

    useEffect(() => {

        const values = props.checklist?.keyValues;

        setState({
            smoothWheelsTurning: values?.smoothWheelsTurning ?? false,
            correctWheelsRamp: values?.correctWheelsRamp ?? false,
            smoothMechanicsGauge: values?.smoothMechanicsGauge ?? false,
            spreadingForceValue: values?.spreadingForceValue ?? 0,
            smoothMechanicsAlign: values?.smoothMechanicsAlign ?? false,
            smoothMechanicsTop: values?.smoothMechanicsTop ?? false,
            sensorsTightening: values?.sensorsTightening ?? false,
            sensorsInMeasuredRanges: values?.sensorsInMeasuredRanges ?? false,
            testedInTrack: values?.testedInTrack ?? false,
            notes: values?.notes ?? '',
            technicianName: values?.technicianName ?? '',
            completed: values?.completed ?? false,
            completedDate: values?.completedDate ?? ''
        });
    }, [props.checklist])

    const handleValueInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [prop]: event.target.value });
        setChanged(true);
    };

    const handleChecledInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.readOnly) {
            setState({ ...state, [prop]: event.target.checked });
            setChanged(true);
        }
    };

    // add other permissions
    const isCompleted = () => !isNullOrWhitespace(state.technicianName);
    const isDisabled = () => props.readOnly || !changed;

    const handleSetUnfinished = () => {
        state.technicianName = "";
        state.completedDate = "";
        state.completed = false;
        setChanged(true);
        handleChecklistSave(false);
    }

    const handleChecklistSave = (setDone: boolean) => {

        if (setDone) {
            state.technicianName = userName;
            state.completedDate = new Date().toISOString();
            state.completed = isCompleted();
        }

        if (props.onSave) {
            props.onSave({
                keyValues: { ...state, completed: setDone },
            });
        }

        setChanged(false);
    }

    const renderAvatar = () => {
        return (
            <Avatar variant='rounded'>
                <AssignmentTurnedInIcon />
            </Avatar>
        )
    }

    function renderTitle() {
        return (
            <Box display='flex' flexDirection='row' alignItems='center'>
                <Typography variant="body1">Checklist</Typography>
                <Box marginLeft={1} />
                <Chip
                    size="small"
                    color={state.completed ? "primary" : "secondary"}
                    variant='outlined'
                    label={state.completed ? "Done " + formatDate(state.completedDate) + " by " + state.technicianName : "Unfinished"}
                    icon={state.completed ? <DoneIcon /> : <ClearIcon />}
                />
            </Box>
        )
    }

    const renderActions = () => {
        return (
            <Tooltip title='Save'>
                <span>
                    <IconButton
                        onClick={() => handleChecklistSave(false)}
                        color='secondary'
                        size='small'
                        disabled={isDisabled()}>
                        <DoneIcon />
                    </IconButton>
                </span>
            </Tooltip>
        )
    }

    return (
        <div className={classes.root}>

            <Card>
                <CardHeader
                    title={renderTitle()}
                    avatar={renderAvatar()}
                    action={renderActions()}
                />

                <CardContent>
                    <Paper className={classes.form}>

                        <div className={classes.horizontalContent}>

                            <div className={classes.verticalContent}>

                                <FormControlLabel
                                    label="Smoth wheels movement"
                                    control={
                                        <Checkbox
                                            readOnly={isCompleted()}
                                            checked={state.smoothWheelsTurning}
                                            onChange={handleChecledInputChange('smoothWheelsTurning')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Wheels flange alignment"
                                    control={
                                        <Checkbox
                                            readOnly={isCompleted()}
                                            checked={state.correctWheelsRamp}
                                            onChange={handleChecledInputChange('correctWheelsRamp')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Smooth gauge mechanism op."
                                    control={
                                        <Checkbox
                                            readOnly={isCompleted()}
                                            checked={state.smoothMechanicsGauge}
                                            onChange={handleChecledInputChange('smoothMechanicsGauge')} />
                                    }
                                />

                                <TextField
                                    value={state.spreadingForceValue}
                                    type='number'
                                    onChange={handleValueInputChange('spreadingForceValue')}
                                    InputProps={{
                                        readOnly: isCompleted(),
                                        startAdornment: <InputAdornment position="start">Lateral force:</InputAdornment>,
                                        endAdornment: <InputAdornment position="end">N</InputAdornment>,
                                    }} />

                            </div>

                            <div className={classes.verticalContent}>

                                <FormControlLabel
                                    label="Smooth Alignmnet mechanism op."
                                    control={
                                        <Checkbox
                                            readOnly={isCompleted()}
                                            checked={state.smoothMechanicsAlign}
                                            onChange={handleChecledInputChange('smoothMechanicsAlign')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Smooth Top mechanism op."
                                    control={
                                        <Checkbox
                                            readOnly={isCompleted()}
                                            checked={state.smoothMechanicsTop}
                                            onChange={handleChecledInputChange('smoothMechanicsTop')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Sensors fastening"
                                    control={
                                        <Checkbox
                                            readOnly={isCompleted()}
                                            checked={state.sensorsTightening}
                                            onChange={handleChecledInputChange('sensorsTightening')} />
                                    }
                                />

                                <FormControlLabel
                                    label="Sensors within the measurement range"
                                    control={
                                        <Checkbox
                                            readOnly={isCompleted()}
                                            checked={state.sensorsInMeasuredRanges}
                                            onChange={handleChecledInputChange('sensorsInMeasuredRanges')} />
                                    }
                                />

                            </div>

                            <div className={classes.verticalContent}>

                                <FormControlLabel
                                    label="Tested on track"
                                    control={
                                        <Checkbox
                                            readOnly={isCompleted()}
                                            checked={state.testedInTrack}
                                            onChange={handleChecledInputChange('testedInTrack')} />
                                    }
                                />

                                <div className={classes.separator} />

                                <TextField
                                    value={state.technicianName}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start">Technician signature:</InputAdornment>,
                                        endAdornment: <InputAdornment position='end' >
                                            {isAdmin && (<IconButton color="secondary" size='small' onClick={() => handleSetUnfinished()}><DeleteIcon /></IconButton>)}
                                        </InputAdornment>
                                    }} />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={isCompleted()}
                                    onClick={() => handleChecklistSave(true)}>
                                    {isCompleted() ? "Signed: " + formatDate(state.completedDate) : "Sign"}
                                </Button>
                            </div>

                        </div>

                        <div className={classes.verticalContent}>

                            <div className={classes.separator} />

                            <TextField
                                label="Additional notes:"
                                multiline
                                variant='outlined'
                                value={state.notes ?? ''}
                                onChange={handleValueInputChange('notes')}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: isCompleted(), }}
                            />

                            <div className={classes.separator} />

                        </div>
                    </Paper>
                </CardContent>
            </Card>


        </div >
    )
}

export default ChecklistCard;