import React, { useEffect, useState } from 'react';

// mui
import { Button, createStyles, FormControl, Icon, IconButton, makeStyles, Paper, TextField, Theme, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

// store
import { useStoreState, useStoreActions, useStoreDispatch } from '../../hooks';
import MaterialTable, { Column } from 'material-table';
import { sortComparer, formatDate } from '../../utils/datetime';
import { VgnDto } from '../../service/dataContract';



const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: theme.spacing(2),
        alignItems: 'center',
    },
    formControl: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(1),
        width: '50%',
        minWidth: 200,
    },
    fileInput: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        alignItems: 'center',
        width: '100%',
    },
    fileDescription: {
        margin: theme.spacing(0, 1),
    },
    chooseButton: {
        flexGrow: 1,
        width: '30%',
        margin: theme.spacing(0, 0, 0, 0),
    },
    paper: {
        margin: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));


interface UploadFileDescriptor {
    file?: File,
}

interface Props {
}

// columns
const columns: Column<VgnDto>[] = [
    { title: 'Name', field: 'deviceName', width: 200 },
    { title: 'Device Version', field: 'deviceVersion', width: 150 },
    { title: 'Device Assembly', field: 'deviceAssembly', width: 150 },
    { title: 'Firmware Version', field: 'firmwareVersion', width: 150 },
    { title: 'Device Expansion', field: 'deviceExpansion', width: 150 },
    { title: 'Radio MAC', field: 'radioMac', width: 150 },
    { title: 'Serial Number', field: 'serialNumber', width: 150 },
    { 
        title: 'Manufactured At', 
        field: 'manufactureDate',
        render: dto => formatDate(dto.manufactureDate),
        defaultSort: 'desc',
        width: 200 },
    { title: 'Comment', field: 'comment' },
    { title: 'Modified by', width: 150, field: 'lastModifiedBy', editable: 'never' },
];

export const VgnImport: React.FC<Props> = props => {

    const classes = useStyles();

    const { fetchVgn, importVgn } = useStoreActions(state => state.vgn);
    const { isLoading, vgnBoards } = useStoreState(state => state.vgn);

    useEffect(() => {
        fetchVgn();
    }, [fetchVgn]);

    let [state, setState] = useState<UploadFileDescriptor>({
        file: undefined,
    })

    async function handleImport() {

        if (state.file) {
            let formData = new FormData();
            formData.append('File', state.file);
            await importVgn({ formData, uploadProgress: () => { } })
        }
    }

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {

        // get list from evt
        const fileList = event.target.files;

        if (fileList) {
            let file = Array.from(fileList)[0];
            setState({ file })
        }

        // clear previously selected
        event.target.value = '';
    }

    function handleDeleteInput() {
        setState({ file: undefined });
    }

    return (

        <div className={classes.root}>

            <Paper className={classes.paper}>

                <Typography variant='h6'>
                    Import Vgn Data
                </Typography>

                <form className={classes.form} autoComplete='off'>


                    <FormControl className={classes.formControl}>


                        <div className={classes.fileInput}>

                            <label htmlFor="vgn_import_csv" className={classes.chooseButton}>

                                <input
                                    style={{ display: 'none' }}
                                    id="vgn_import_csv"
                                    name="upload-file"
                                    type="file"
                                    accept=".csv"
                                    multiple={false}
                                    onChange={handleInputChange}
                                />

                                <Button
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    component="span">
                                    Open file
                                </Button>
                                
                            </label>


                            <TextField
                                className={classes.fileDescription}
                                fullWidth
                                variant='outlined'
                                size='small'
                                label='Selected File'
                                value={state.file?.name ?? ""}
                                InputLabelProps={{ shrink: true }} >
                            </TextField>

                            <IconButton
                                color="secondary"
                                component="span"
                                onClick={() => handleDeleteInput()}>
                                <DeleteIcon />
                            </IconButton>

                        </div>


                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <Button
                            disabled={state.file === undefined}
                            onClick={() => handleImport()}
                            color='primary'
                            variant='contained'>
                            Upload
                    </Button>
                    </FormControl>

                </form>

            </Paper>

            <MaterialTable
                title="Imported VGN devices"
                isLoading={isLoading}
                columns={columns}
                data={vgnBoards}
                options={{
                    filtering: true,
                    paging: false,
                }}
            />

        </div>


    );
}

export default VgnImport;