import { action, Action, Thunk, thunk } from 'easy-peasy';
import { StoreContext } from './';
import store, { Injections } from '../store';

// dto
import { LicenseBriefDto, LicensePayloadDto, LicenseQueryDto, CreateLicenseDto, LicenseType } from '../service/dataContract';
import { StoreMode, storeValue } from '../utils/storageUtils';
import { base64toBlob } from '../utils/fileUtils';
import { Progress } from '../service/httpClient';
import { dateComparer } from '../utils/datetime';

const buildLicenseForm = (license: CreateLicenseDto) => {

    let formData = new FormData();

    // append license contents as a json
    formData.append('Data', JSON.stringify(license));

    // append license file for (deprecated file license)
    if (license.licenseFile && license.licenseType == LicenseType.File)
        formData.append('File', license.licenseFile);

    return formData;
}

// device store interface
export interface LicenseModel {

    isLoading: boolean,
    latestChangeTimestamp: Date | undefined,
    licenses: LicenseBriefDto[],

    fetchLicenses: Thunk<LicenseModel, { query: LicenseQueryDto }, Injections, StoreContext>,
    addLicense: Thunk<LicenseModel, { license: CreateLicenseDto }, Injections, StoreContext, Promise<LicenseBriefDto>>,
    updateLicense: Thunk<LicenseModel, { licenseId: string, license: CreateLicenseDto }, Injections, StoreContext, Promise<LicenseBriefDto>>,
    deleteLicense: Thunk<LicenseModel, { licenseId: string }, Injections, StoreContext>,

    // download license file
    downloadLicense: Thunk<LicenseModel, { licenseId: string, progress?: Progress }, Injections, StoreContext>,

    setLoading: Action<LicenseModel, boolean>,

    clear: Action<LicenseModel>,
    refreshLicenseList: Action<LicenseModel, { licenses: LicenseBriefDto[] }>,
    storeLicense: Action<LicenseModel, { mode: StoreMode, license?: LicenseBriefDto, licenseId?: string }>,

};

const licenseModel: LicenseModel = {

    isLoading: false,
    latestChangeTimestamp: undefined,
    licenses: [],

    setLoading: action((store, payload) => { store.isLoading = payload; }),

    clear: action((store) => {
        store.licenses = [];
        store.latestChangeTimestamp = undefined;
    }),

    storeLicense: action((store, payload) => {
        store.licenses = storeValue(store.licenses, 'id', { mode: payload.mode, id: payload.licenseId, item: payload.license });
    }),

    refreshLicenseList: action((store, payload) => {

        payload.licenses.forEach(license => {
            store.licenses = storeValue(store.licenses ?? [], 'id', { mode: 'merge', id: license.id, item: license });
        });

        if (store.licenses.length > 0) {
            store.latestChangeTimestamp = store.licenses.reduce((prev, current) => (dateComparer(prev.lastModifiedAt, current.lastModifiedAt)) ? prev : current)?.lastModifiedAt;
        }
    }),


    fetchLicenses: thunk(async (actions, payload, { injections, getStoreActions, getState }) => {

        const { licenseClient } = injections;

        actions.setLoading(true);

        await licenseClient.fetch({ query: { updatedSince: getState().latestChangeTimestamp } })
            .then(licenses => {
                actions.refreshLicenseList({ licenses });
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to fetch license list.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),


    addLicense: thunk(async (actions, payload, { injections, getStoreActions, getStoreState }) => {

        const { licenseClient } = injections;

        actions.setLoading(true);

        let formData = buildLicenseForm(payload.license);

        return await licenseClient.create({ formData, progress: () => { } })
            .then(license => {
                actions.storeLicense({ mode: 'insert', licenseId: license.id, license });
                getStoreActions().audit.notify({ severity: 'info', message: `License has been sucessfully created.` })
                return Promise.resolve(license);
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create license.`, payload: err })
                return Promise.reject(err);
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

    updateLicense: thunk(async (actions, payload, { injections, getStoreActions, getStoreState }) => {

        const { licenseClient } = injections;
        actions.setLoading(true);


        let formData = buildLicenseForm(payload.license);

        return await licenseClient.update({ licenseId: payload.licenseId, formData, progress: () => { } })
            .then(license => {
                actions.storeLicense({ mode: 'update', licenseId: payload.licenseId, license });
                getStoreActions().audit.notify({ severity: 'info', message: `License has been sucessfully updated.` })
                return Promise.resolve(license);
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update license.`, payload: err })
                return Promise.reject(err);
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

    downloadLicense: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { licenseClient } = injections;

        await licenseClient.get(payload)
            .then(dto => {

                // get data (extract file name, convert blob from base64 to utf8)
                const fname = dto.fileName ?? 'sw_downloaded_license_file.unknown';
                const blob = base64toBlob(dto.fileContent ?? '');

                // create download link
                let tempLink = document.createElement('a');
                tempLink.href = window.URL.createObjectURL(blob);
                tempLink.setAttribute('download', fname);
                tempLink.click();

                getStoreActions().audit.notify({ severity: 'info', message: `Downloading file has been initiated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Download license file error.`, payload: err });
            });
    }),

    deleteLicense: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { licenseClient } = injections;
        actions.setLoading(true);

        return await licenseClient.delete(payload)
            .then(_ => {
                actions.storeLicense({ mode: 'delete', licenseId: payload.licenseId });
                getStoreActions().audit.notify({ severity: 'info', message: `License has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete license.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),

}

export default licenseModel;
