import authModel, { AuthModel } from './auth';
import deviceModel, { DeviceModel } from './device';
import componentState, { DeviceComponentState } from './deviceComponent';
import servState, { ServiceState } from './service';
import softwareModel, { SoftwareModel } from './software';
import licenseModel, { LicenseModel } from './license';
import userModel, { UserModel } from './user';
import auditModel, { AuditModel } from './audit';
import vgnModel, { VgnModel } from './vgn';



// global store interface
export interface StoreContext {
    auth: AuthModel,
    device: DeviceModel,
    software: SoftwareModel,
    license: LicenseModel,
    component: DeviceComponentState,
    service: ServiceState,
    user: UserModel,
    audit: AuditModel,
    vgn: VgnModel,
    // .. add interfaces
};

const storeContext: StoreContext = {
    auth: authModel,
    user: userModel,
    device: deviceModel,
    software: softwareModel,
    license: licenseModel,
    component: componentState,
    service: servState,
    audit: auditModel,
    vgn: vgnModel,
    // .. add models
}

export default storeContext;