import { http, Progress, fileIOTimeout } from './httpClient';
import { ServiceOperationDto, CreateServiceOperationDto, DriveItemsQueryDto, DriveItemDto } from './dataContract'

import { buildQuery } from '../utils/query'

const baseRoute = (deviceId: string) => `/devices/${deviceId}/serviceOperations`;

const routes = {
    create: (deviceId: string) => `${baseRoute(deviceId)}`,
    update: (deviceId: string, serviceId: string) => `${baseRoute(deviceId)}/${serviceId}`,
    delete: (deviceId: string, serviceId: string) => `${baseRoute(deviceId)}/${serviceId}`,

    fetchDriveItems: (deviceId: string, serviceId: string, query: DriveItemsQueryDto) => `${baseRoute(deviceId)}/${serviceId}/drive/?${buildQuery(query, '')}`,
    uploadDriveItem: (deviceId: string, serviceId: string) => `${baseRoute(deviceId)}/${serviceId}/drive/`,
    downloadDriveItem: (deviceId: string, serviceId: string, path: string) => `${baseRoute(deviceId)}/${serviceId}/driveItem/?path=${encodeURIComponent(path)}`,
    deleteDriveItem: (deviceId: string, serviceId: string, path: string) => `${baseRoute(deviceId)}/${serviceId}/driveItem/?path=${encodeURIComponent(path)}`,
}

export interface ServiceClient {
    create(payload: { deviceId: string, service: CreateServiceOperationDto }): Promise<ServiceOperationDto>,
    update(payload: { deviceId: string, serviceId: string, service: CreateServiceOperationDto }): Promise<ServiceOperationDto>,
    delete(payload: { deviceId: string, serviceId: string }): Promise<boolean>,

    fetchDriveItems(payload: { deviceId: string, serviceId: string, query: DriveItemsQueryDto }): Promise<DriveItemDto[]>,
    uploadDriveItem(payload: { deviceId: string, serviceId: string, formData: FormData, uploadProgress?: (event: ProgressEvent) => void }): Promise<DriveItemDto>,
    downloadDriveItem(payload: { deviceId: string, serviceId: string, path: string, downloadProgress?: (event: ProgressEvent) => void }): Promise<DriveItemDto>,
    deleteDriveItem(payload: { deviceId: string, serviceId: string, path: string }): Promise<boolean>,
}

const serviceClient: ServiceClient = {
    create: (payload) => http.post(routes.create(payload.deviceId), payload.service),
    update: (payload) => http.put(routes.update(payload.deviceId, payload.serviceId), payload.service),
    delete: (payload) => http.delete(routes.delete(payload.deviceId, payload.serviceId)),

    fetchDriveItems: (payload) => http.get(routes.fetchDriveItems(payload.deviceId, payload.serviceId, payload.query)),

    uploadDriveItem: (payload) => http.post(
        routes.uploadDriveItem(payload.deviceId, payload.serviceId),
        payload.formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: payload.uploadProgress,
            timeout: fileIOTimeout,
        }),

    downloadDriveItem: (payload) => http.get(
        routes.downloadDriveItem(payload.deviceId, payload.serviceId, payload.path),
        {
            headers: { 'Content-Type': 'multipart/form-data' },
            onDownloadProgress: payload.downloadProgress,
            timeout: fileIOTimeout,
        }),

    deleteDriveItem: (payload) => http.delete(routes.deleteDriveItem(payload.deviceId, payload.serviceId, payload.path)),}

export default serviceClient;