import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

// store
import { useStoreActions, useStoreState } from '../../hooks';
import { LicenseBriefDto, LicenseType } from '../../service/dataContract';

// mui
import { Chip, Paper, Typography, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable, { Column } from 'material-table';

// components
import { formatDate } from '../../utils/datetime';
import { isType } from '../../utils/typeUtils';
import FileLicenseCard from './FileLicenseCard';
import KzvLicenseCard from './KzvLicenseCard';

// routes
import routes from '../../routes';
import { isValid } from 'date-fns/esm';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    detailPanel: {
        background: '#EEEE',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'left',
        minHeight: 100,
        margin: theme.spacing(0),
    },
    cell: {
        display: 'flex',
    },
    module: {
        margin: theme.spacing(0.0, 0.0, 0.0, 1.0),
    }
}));

interface Props {
    deviceId?: string,
    paging?: boolean,
    grouping?: boolean,
    filtering?: boolean,
    toolbar?: boolean,
}

export const LicenseListCard: React.FC<Props> = props => {

    const classes = useStyles();
    const history = useHistory();

    const { isKzv } = useStoreState(state => state.auth);
    const { isLoading, licenses } = useStoreState(state => state.license);
    const { fetchLicenses, deleteLicense, downloadLicense } = useStoreActions(state => state.license);

    useEffect(() => {
        async function fetchData() {
            await fetchLicenses({
                query: {}
            });
        };
        
        fetchData();
    }, [fetchLicenses]);

    const renderLicenseType = (dto: LicenseBriefDto) => {

        const licenseType = dto?.licenseType ?? LicenseType.NotSet;

        return (
            <Chip
                color='primary'
                size='small'
                label={<Typography variant='caption'>{LicenseType[licenseType]}</Typography>}
                variant={licenseType === LicenseType.File ? 'outlined' : 'default'}
            />
        )
    }

    const renderLicenseStatus = (dto: LicenseBriefDto) => {

        const daysToMilliseconds = 24 * 60 * 60 * 1000;

        const currentDate = new Date();
        const signatureDate = new Date(dto.lastModifiedAt ?? "") ?? currentDate;  // signature is regenerated when updated the same date
        const expirationDate = new Date(dto.expiresAt ?? "") ?? new Date(Number.MAX_VALUE)
        const timeEllapsed = currentDate.getTime() - signatureDate.getTime();

        const trialDays: number = dto.trialDays ?? 0;
        const trialTime = trialDays * daysToMilliseconds;
        const isLicenseExpired = expirationDate < currentDate;

        const isTrial = () => trialDays > 0;
        const isTrialExpired = isTrial() && timeEllapsed > trialTime;
        const trialDaysRemains = ((trialTime - timeEllapsed) < 0 ? 0 : trialTime - timeEllapsed) / daysToMilliseconds;

        const isExpired = isTrialExpired || isLicenseExpired;
        const activationInfo = trialDays == 0 ? "Activated" : `Trial ${trialDaysRemains.toFixed(0)}/${trialDays} days`;
        const expirationInfo = isValid(expirationDate) && expirationDate < new Date(9999) ? formatDate(expirationDate) : "Unlimited"

        switch (dto?.licenseType) {

            case LicenseType.File:
                return (<Chip label='N/A' variant='outlined' size='small'></Chip>)

            default:
                return (
                    <>
                        <Chip
                            size="small"
                            variant={isExpired ? 'default' : 'outlined'}
                            color={isExpired ? 'secondary' : 'primary'}
                            label={expirationInfo} />

                        <Chip
                            size="small"
                            variant={isExpired ? 'default' : 'outlined'}
                            color={isExpired ? 'secondary' : 'primary'}
                            label={activationInfo} />
                    </>
                )
        }
    }

    const renderLicenseDetail = (dto: LicenseBriefDto) => {

        switch (dto?.licenseType) {

            case LicenseType.File:
                return (
                    <Paper className={classes.detailPanel}>
                        <FileLicenseCard data={dto} />
                    </Paper>
                )
                break;

            default:
                return (
                    <Paper className={classes.detailPanel}>
                        <KzvLicenseCard data={dto} />
                    </Paper>
                )
                break;

        }
    }

    const filterLicensesByDeviceId = () => {

        if (props.deviceId === undefined)
            return licenses;

        return licenses.filter(license => license.deviceId === props.deviceId);
    }

    const columns: Column<LicenseBriefDto>[] = [
        { title: 'S/N', field: 'serialNumber', width: 150 },
        { title: 'Software', field: 'softwareName', grouping: true, width: 300 },
        { title: 'File Name', field: 'fileName', grouping: true, width: '50%' },
        { title: 'Country', field: 'ownerCountryCode', },
        { title: 'Owner', field: 'ownerName', },
        { title: 'License', field: 'licenseType', width: 100, render: renderLicenseType, grouping: false, filtering: false },
        { title: 'Note', field: 'note', grouping: false, width: '100%' },
        { title: 'Status', render: renderLicenseStatus, grouping: false, width: '100%' },
        { title: 'Description', field: 'internalDescription', grouping: false, width: '100%' },
        { title: 'Modified by', field: 'lastModifiedBy', editable: 'never', grouping: false, width: 150 },
        { title: 'Modified at', field: 'lastModifiedAt', render: dto => formatDate(dto.lastModifiedAt), editable: 'never', grouping: false, width: 150 },
        { title: 'Created by', field: 'createdBy', editable: 'never', hidden: true, width: 150, grouping: false },
        { title: 'Created at', field: 'createdAt', type: 'date', editable: 'never', width: 150, hidden: true, grouping: false, defaultSort: 'desc' },
    ];

    // event handlers
    const handleDelete = (oldData: LicenseBriefDto): Promise<void> =>
        new Promise((resolve) => {
            deleteLicense({ licenseId: oldData.id ?? '' });
            resolve();
        });


    return (
        <div className={classes.root}>
            <MaterialTable
                title={("Licenses")}
                isLoading={isLoading}
                columns={columns}
                data={filterLicensesByDeviceId()}
                detailPanel={[
                    ({
                        tooltip: 'Modules',
                        icon: () => (<ExpandMoreIcon color='primary' />),
                        render: renderLicenseDetail
                    }),
                ]}
                options={{
                    ...props,
                    pageSize: 20,
                    pageSizeOptions: [10, 20, 50],
                    addRowPosition: 'first',
                }}
                localization={{ body: { editRow: { deleteText: 'Delete License?' } } }}
                editable={{
                    onRowDelete: isKzv ? handleDelete : undefined,
                }}
                actions={[
                    {
                        isFreeAction: true,
                        icon: 'add',
                        tooltip: 'Create a new License',
                        onClick: (_, dto) => {
                            history.push(routes.licenseCreate);
                        }
                    },
                    {
                        isFreeAction: false,
                        icon: 'download',
                        tooltip: 'Download',
                        onClick: (_, dto) => {
                            if (isType<LicenseBriefDto>(dto))
                                downloadLicense({ licenseId: dto.id });
                        }
                    }

                ]}
                onRowClick={(event, rowData, togglePanel) => {
                    if (togglePanel) togglePanel();
                }}
            >
            </MaterialTable>
        </div>
    );
}

export default LicenseListCard;

