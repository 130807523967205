import { http } from './httpClient';
import { DeviceQueryDto, DeviceBriefDto, DeviceDto, CreateDeviceDto } from './dataContract'
import { buildQuery } from '../utils/query'

const baseRoute = '/devices'

const routes = {
    fetch: (query: DeviceQueryDto) => `${baseRoute}/?${buildQuery(query, 'Query.')}`,
    get: (deviceId: string) => `/devices/${deviceId}`,
    getlastUsedSerial: (deviceTypeId: string) => `/devices/lastSerialNumber/${deviceTypeId}`,
    create: `${baseRoute}`,
    clone: (deviceId: string) => `${baseRoute}/${deviceId}/clone`,
    update: (deviceId: string) => `${baseRoute}/${deviceId}`,
    delete: (deviceId: string) => `${baseRoute}/${deviceId}`,

    uploadLicenseFile: (deviceId: string) => `${baseRoute}/${deviceId}/licenseFiles`,
    updateLicenseFile: (deviceId: string, fileId: string) => `${baseRoute}/${deviceId}/licenseFiles/${fileId}`,
    downloadLicenseFile: (deviceId: string, fileId: string) => `${baseRoute}/${deviceId}/licenseFiles/${fileId}`,
    deleteLicenseFile: (deviceId: string, fileId: string) => `${baseRoute}/${deviceId}/licenseFiles/${fileId}`,

    restore: (deviceId: string) => `${baseRoute}/${deviceId}/restore`,
}

export interface DeviceClient {
    fetch(payload: { query: DeviceQueryDto }): Promise<DeviceBriefDto[]>,
    get(payload: { deviceId: string }): Promise<DeviceDto>,
    getLastUsedSerial(payload: { deviceTypeId: string }): Promise<string>,

    create(payload: { device: CreateDeviceDto }): Promise<DeviceDto>,
    update(payload: { deviceId: string, device: CreateDeviceDto }): Promise<DeviceDto>,
    delete(payload: { deviceId: string }): Promise<boolean>,

    clone(payload: { deviceId: string, serialNumber: string, name?: string }): Promise<DeviceDto>,
    restore(payload: { deviceId: string, serialNumber?: string }): Promise<boolean>,
}

const deviceClient: DeviceClient = {
    fetch: (payload) => http.get<DeviceBriefDto[]>(routes.fetch(payload.query)),
    get: (payload) => http.get(routes.get(payload.deviceId)),
    getLastUsedSerial: (payload) => http.get(routes.getlastUsedSerial(payload.deviceTypeId)),

    create: (payload) => http.post(routes.create, payload.device),
    update: (payload) => http.put(routes.update(payload.deviceId), payload.device),
    delete: (payload) => http.delete(routes.delete(payload.deviceId)),

    clone: (payload) => http.post(routes.clone(payload.deviceId), {
        serialNumber: payload.serialNumber,
        name: payload.name,
    }),
   
    restore: (payload) => http.put(
        routes.restore(payload.deviceId),
        {             
            serialNumber: payload.serialNumber
        }),
    
}

export default deviceClient;