import React, { useEffect, useState } from 'react';

// store
import { useStoreState, useStoreActions, useStoreDispatch } from '../../hooks'
import { CreateSoftwareVersionDto, SoftwareBriefDto, } from '../../service/dataContract';

// mui
import { Box, Tab, Tabs, Typography, Checkbox, useTheme, Chip, } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MaterialTable, { Column } from 'material-table';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { validateInput, validateDefined, validateRx } from '../../utils/validationUtils';
import { formatDate } from '../../utils/datetime';

// components and modules
import VersionListEditor from './SoftwareVersionListEditor';
import ModuleListEditor from './SoftwareModuleListEditor';
import WatcherListEditor from './SoftwareWatcherListEditor';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    detailPanel: {
        background: '#EEEE',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        minHeight: 200,
    },
    module: {
        display: 'flex',
        flexDirection: 'row',
    },
    chipCell: {
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'row',
    },
    versionCard: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '33%',
        flexGrow: 1,
    },
    moduleCard: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '33%',
        flexGrow: 1,
    },
    watcherCard: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '33%',
        flexGrow: 1,
    },
    fab: {
        margin: theme.spacing(1),
    },
}));


interface Props {
}

export const SoftwareListCard: React.FC<Props> = props => {

    const classes = useStyles();

    const { softwares, isLoading } = useStoreState(state => state.software);
    const { fetchSoftwares, addSoftware, updateSoftware, deleteSoftware } = useStoreActions(state => state.software);

    useEffect(() => {
        fetchSoftwares({ query: {} });
    }, [fetchSoftwares]);

    const softwareColumns: Column<SoftwareBriefDto>[] = [
        {
            title: 'Name',
            field: 'name',
            width: '100%',
            validate: rowData => validateInput(rowData.name, 2),
        },
        {
            title: 'Watchers',
            render: dto => renderSoftwareWatchers(dto),
            width: '100%',
            field: 'watchers',
            editable: 'never'
        },
        {
            title: 'Modules',
            render: dto => renderSoftwareModules(dto),
            width: '100%',
            field: 'modules',
            editable: 'never'
        },
        {
            title: 'Versions',
            render: dto => renderSoftwareVersions(dto),
            width: '100%',
            editable: 'never',
        },

        { title: 'Modified by', width: 150, field: 'lastModifiedBy', editable: 'never' },
        { title: 'Modified at', width: 150, field: 'lastModifiedAt', render: dto => formatDate(dto.lastModifiedAt), editable: 'never', defaultSort: 'desc' },
        { title: 'Created by', width: 150, field: 'createdBy', editable: 'never', hidden: true },
        { title: 'Created at', width: 150, field: 'createdAt', type: 'date', editable: 'never', hidden: true },
    ];

    const renderSoftwareWatchers = (dto: SoftwareBriefDto) => {
        return (
            <div className={classes.chipCell}>
                {dto.softwareWatchers?.map(watcher => (
                    <Chip
                        key={dto.id}
                        color='primary'
                        size='small'
                        label={<Typography variant='caption'>{watcher.email}</Typography>}
                        variant='default'
                    />
                ))}
            </div>
        )
    }

    const renderSoftwareModules = (dto: SoftwareBriefDto) => {
        return (
            <div className={classes.chipCell}>
                {dto.softwareModules?.map(module => (
                    <Chip
                        key={dto.id}
                        color='primary'
                        size='small'
                        label={<Typography variant='caption'>{module.name}</Typography>}
                        variant='default'
                    />
                ))}
            </div>
        )
    }

    const renderSoftwareVersions = (dto: SoftwareBriefDto) => {
        return (
            <div className={classes.chipCell}>
                {dto.softwareVersions?.map(softwareVersion => (
                    <Chip
                        key={softwareVersion.id}
                        color='primary'
                        size='small'
                        label={<Typography variant='caption'>{softwareVersion.version}</Typography>}
                        variant='default'
                    />
                ))}
            </div>
        )
    }

    const renderDetail = (dto: SoftwareBriefDto) => {
        return (
            <div className={classes.detailPanel}>

                <WatcherListEditor
                    software={dto}
                    className={classes.watcherCard}
                />

                <ModuleListEditor
                    software={dto}
                    className={classes.moduleCard}
                />

                <VersionListEditor
                    software={dto}
                    className={classes.versionCard}
                />

            </div >
        );
    }

    // software - event handlers
    const handleSoftwareCreate = (newData: SoftwareBriefDto): Promise<void> =>
        new Promise((resolve, reject) => {
            addSoftware({ software: newData });
            resolve();
        });

    const handleSoftwareUpdate = (newData: SoftwareBriefDto, oldData?: SoftwareBriefDto): Promise<void> =>
        new Promise((resolve) => {
            updateSoftware({ softwareId: newData?.id ?? '', software: newData });
            resolve();
        });

    const handleSoftwareDelete = (oldData: SoftwareBriefDto): Promise<void> =>
        new Promise((resolve) => {
            deleteSoftware({ softwareId: oldData.id });
            resolve();
        });


    return (
        <div className={classes.root}>
            <MaterialTable
                title="Softwares"
                isLoading={isLoading}
                columns={softwareColumns}
                data={softwares}
                detailPanel={[
                    ({
                        tooltip: 'Modules',
                        icon: () => (<ExpandMoreIcon color='primary' />),
                        render: renderDetail
                    }),
                ]}
                options={{
                    filtering: false,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 20],
                    addRowPosition: 'first',
                }}
                editable={{
                    onRowAdd: handleSoftwareCreate,
                    onRowUpdate: handleSoftwareUpdate,
                    onRowDelete: handleSoftwareDelete,
                }}
                onRowClick={(event, rowData, togglePanel) => {
                    if (togglePanel) togglePanel();
                }}
            >
            </MaterialTable>
        </div>
    );
}

export default SoftwareListCard;
