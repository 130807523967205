import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from '../hooks';

// mui
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

import { VgnBoardDto, VgnDto } from '../service/dataContract';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
        },
        margin: {
            margin: theme.spacing(1),
        },
    }),
);

export interface Props {
    className?: string,
    label?: string,
    selection: VgnBoardDto[],
    disabled?: boolean;
    onChange?: (selection: VgnBoardDto[]) => void,
}

export const MultipleVgnBoardInput: React.FC<Props> = (props) => {

    const classes = useStyles();

    const { fetchVgn } = useStoreActions(state => state.vgn);
    const { vgnBoards } = useStoreState(state => state.vgn);

    useEffect(() => {
        const fetchData = async () => { fetchVgn(); }
        fetchData();
    }, [fetchVgn]);


    const [state, setState] = useState<VgnDto[]>([]);

    useEffect(() => {
        setState(vgnBoards.filter(board => props.selection.find(selection => selection.serialNumber == board.serialNumber)));
    }, [props.selection, vgnBoards]);

    const handleChange = (selection: VgnDto[]) => {
        setState(selection);
        props.onChange?.(selection.map<VgnBoardDto>(vgn => { return { requiredAuth: 'None', serialNumber: vgn.serialNumber ?? '' } }));
    };

    return (
        <Autocomplete
            multiple
            className={props.className}
            disabled={props.disabled}
            options={vgnBoards}
            value={state}
            fullWidth
            getOptionLabel={(option) => `${option.deviceName}, SN: ${option.serialNumber}, Auth: ${option.requiredAuth?? 'None'}`}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={props.label}
                    helperText={state.length == 0 ? 'Some Vgn Bard or (Any *) must be explicitly selected' : undefined}
                    error={state.length == 0}
                    InputLabelProps={{ shrink: true }}
                    variant='standard'
                />}
            onChange={(_, selection) => handleChange(selection)}
        />
    )
}

export default MultipleVgnBoardInput;