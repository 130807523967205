import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// material ui
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blue, red, green, blueGrey } from '@material-ui/core/colors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// components
import { Home } from './pages/Home';
import { Software } from './pages/Software';
import { CreateLicenseCard } from './software/CreateLicenseCard';
import { DeviceDetail } from './device/DeviceDetail';
import { ComponentAdmin } from './ComponentAdmin';
import { Users } from './pages/Users';
import { DeviceConfig } from './device/DeviceConfig';
import { DeviceCreate } from './device/DeviceCreate';
import { DeviceClone } from './device/DeviceClone';
import { VgnImport } from './pages/VgnImport';
import { Login } from './pages/Login';
import { MainLayout } from './pages/MainLayout';
import { NotFound } from './pages/NotFound';
import { Test } from './pages/Test';
import PrivateRoute from './PrivateRoute';

// routes
import routes from '../routes';
import Admin from './pages/Admin';

function App() {

    // get theme
    const theme = createMuiTheme({
        spacing: 8,
        typography:
        {
            fontSize: 14,
            fontFamily: 'roboto',
        },
        palette: {
            background: {
                default: '#FAFAFA',
            },
            primary: {
                main: process.env.NODE_ENV === 'development' ? green[500] : blue[500],                
                light: process.env.NODE_ENV === 'development' ? blueGrey[50] : blueGrey[50],                       
            },
            error: {
                main: red[500],
                light: red[300],
            }
        },
    });

    theme.overrides = {
        MuiCard: {
            root: {
                padding: theme.spacing(0),
                margin: theme.spacing(0.5),
            }
        },
        MuiCardHeader: {
            root: {
                padding: theme.spacing(0),
                margin: theme.spacing(0.5),
                backgroundColor: theme.palette.primary.light,
            },
            action: {
                paddingTop: theme.spacing(1),
                margin: theme.spacing(0),
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                width: '90%',
                height: '100%',
            },
        },
        MuiCardContent: {
            root: {
                padding: theme.spacing(0),
                margin: theme.spacing(0.5),
                "&:last-child": {
                    paddingBottom: theme.spacing(0),
                },
            }
        },
        MuiCardActions: {
            root: {
                padding: theme.spacing(0),
                margin: theme.spacing(0.5),
            }
        },
        MuiCardActionArea: {
            root: {
                padding: theme.spacing(0),
                margin: theme.spacing(0.5),
            }
        },

        MuiAvatar: {
            root: {
                width: 30,
                height: 30,
            },
            rounded: {
                color: theme.palette.info.main,
                backgroundColor: 'transparent'
            },
            square: {
                color: theme.palette.info.main,
                backgroundColor: 'transparent'
            },
            circle: {
                color: theme.palette.info.main,
                backgroundColor: 'transparent'
            }
        },
        MuiSvgIcon: {
            root: {
                width: 25,
                height: 25
            }
        }
    }

    return (

        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <MainLayout>
                        <Switch>
                            <Route path={routes.login} exact component={Login} />
                            <PrivateRoute path={routes.home} component={Home} />
                            <PrivateRoute path={routes.deleted} exact component={Home} />
                            <PrivateRoute path={routes.components} component={ComponentAdmin} />
                            <PrivateRoute path={routes.users} component={Users} />
                            <PrivateRoute path={routes.softwate} exact component={Software} />
                            <PrivateRoute path={routes.licenseCreate} exact component={CreateLicenseCard} />
                            <PrivateRoute path={routes.deviceCreate} component={DeviceCreate} />
                            <PrivateRoute path={routes.deviceDetail.path} exact component={DeviceDetail} />
                            <PrivateRoute path={routes.deviceClone.path} exact component={DeviceClone} />
                            <PrivateRoute path={routes.vgnImport} component={VgnImport} />
                            <PrivateRoute path={routes.admin} component={() => (<Admin />)} />
                            <PrivateRoute path={routes.test} component={() => (<Test></Test>)} />
                            <PrivateRoute path='/' exact component={Home}></PrivateRoute>
                            <Route path='/' component={NotFound}></Route>
                        </Switch>
                    </MainLayout>
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </ThemeProvider>

    )
}

export default App;