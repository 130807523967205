import React, { useEffect } from 'react';

// store
import { useStoreState, useStoreActions } from '../../hooks'

// mui
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MaterialTable, { Column, EditComponentProps } from 'material-table';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import ShareIcon from '@material-ui/icons/Share';
// components
import ServiceDetailCard from './ServiceDetailCard';

// dtos
import { ServiceOperationDto } from '../../service/dataContract';
import { getLookupKey, serviceStateLookup } from '../../model/codelist';

// utils
import { validateInput, validateDefined, validateRx, nameRxPattern } from '../../utils/validationUtils';
import { ValidationResult, ValidationRules } from '../../utils/validations';
import { formatDate } from '../../utils/datetime';
import { DetailsSharp } from '@material-ui/icons';


// styles
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    detailPanel: {
        background: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'left',
        margin: theme.spacing(1),
    },
}));

// constants
const plannedStatedKey = getLookupKey(serviceStateLookup, 'Planned');

// props with expected route params
interface Props {
    readonly: boolean,
}

interface State extends ServiceOperationDto {
}

// setup validation rules
const rules: ValidationRules<State> = {

}

// device service tool
export const ServiceCard: React.FC<Props> = props => {

    const classes = useStyles();

    const { deviceDetail, isLoading } = useStoreState(state => state.device);
    const { addService, updateService, deleteService } = useStoreActions(state => state.service);
    const { notify } = useStoreActions(state => state.audit);
    const { isAdmin, isKzv, userName } = useStoreState(state => state.auth);

    const isOwner = (data: ServiceOperationDto): boolean => {
        return isKzv || data.createdBy === userName;
    }

    const renderLinkButton = (sop: ServiceOperationDto) => {
        return (isKzv &&
            <Tooltip title='Sharepoint Link' placement='top'>
                <span>
                    <IconButton
                        onClick={() => {
                            let tempLink = document.createElement('a');
                            tempLink.href = `https://kzvcz1.sharepoint.com/sites/Uloziste/Devices/${deviceDetail?.sharepointDirectoryPath}/${sop.sharepointDirectoryPath ?? ''}`;
                            tempLink.target = '_blank';
                            tempLink.click();
                            tempLink.remove();
                        }}
                        color='primary'
                        size='small'
                        disabled={false}>
                        <ShareIcon />
                    </IconButton>
                </span>
            </Tooltip>
        )
    }

    const dateEditComponnet = (props: EditComponentProps<ServiceOperationDto>) => {
        return (
            <KeyboardDatePicker
                format='dd.MM.yyyy'
                value={props.value || null}
                onChange={date => {
                    if (date !== null) {
                        // preserving the time of action (hours, minutes, sec) which is discarded by MUIdatepicker when keyboard input
                        date.setHours(new Date().getHours());
                        date.setMinutes(new Date().getMinutes());
                        date.setSeconds(0);
                    }
                    props.onChange(date);
                }}
                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                clearable
                disableToolbar
                variant="inline"
            />
        )
    }

    const validateDate = (date: Date | null | undefined): ValidationResult => {

        // undefined / cleared
        if (date === null || date === undefined) {
            return { isValid: true };
        }

        // invalid date
        if (isNaN(new Date(date ?? '').getTime())) {
            return { isValid: false, helperText: 'Invalid date format' };
        }

        return { isValid: true };
    }

    const validateContract = (newSop: ServiceOperationDto): ValidationResult => {

        var found = deviceDetail?.serviceOperations?.find(exSop => {

            var newDate = new Date(newSop.createdAt ?? '');   
            var exDate = new Date(exSop.createdAt ?? '');

            return exSop.id !== newSop.id
                && exSop.subject?.trim() === newSop.subject?.trim()
                && newDate.getFullYear() === exDate.getFullYear()
                && newDate.getMonth() === exDate.getMonth()
                && newDate.getDate() === exDate.getDate();
        });

        if (found) {
            return { isValid: false, helperText: 'Service with same subject and creation date already exists.' };
        }

        return validateRx(newSop.subject, nameRxPattern, 'Enter at least 5 characters, no ending dot.');
    }


    const columns: Column<ServiceOperationDto>[] = [
        {
            title: '',
            render: renderLinkButton,
        },
        {
            title: 'Created at',
            field: 'createdAt',
            width: 250,
            type: 'date',
            editable: (_, rowData) => isOwner(rowData),
            editComponent: dateEditComponnet,
            render: rowData => formatDate(rowData.createdAt),
            validate: rowData => validateDate(rowData.createdAt),
            defaultSort: 'desc'
        },
        {
            title: 'Subject',
            field: 'subject',
            width: '80%',
            editable: (_, rowData) => isOwner(rowData),
            validate: rowData => validateRx(rowData.subject, nameRxPattern, 'Enter at least 5 characters, no ending dot.')
        },
        {
            title: 'Order No.',
            field: 'order',
            width: '20%',
        },
        {
            title: 'Status',
            field: 'state',
            width: 250,
            lookup: serviceStateLookup,
            initialEditValue: plannedStatedKey,
            editable: (_, rowData) => isOwner(rowData),
            validate: rowData => validateDefined(rowData.state)
        },
        {
            title: 'Accepted',
            width: 250,
            field: 'acceptedAt',
            type: 'date',
            initialEditValue: new Date(),
            editable: (_, rowData) => isOwner(rowData),
            editComponent: dateEditComponnet,
            render: rowData => formatDate(rowData.acceptedAt),
            validate: rowData => validateDefined(rowData.acceptedAt)
        },
        {
            title: 'Handed out',
            width: 250,
            field: 'handedOutAt',
            type: 'date',
            editComponent: dateEditComponnet,
            render: rowData => formatDate(rowData.handedOutAt)
        },
        { title: 'Technician', width: 150, render: rowData => rowData.createdBy },
        { title: 'Modified by', width: 200, field: 'lastModifiedBy', editable: 'never', hidden: true },
        { title: 'Touched', width: 200, field: 'lastModifiedAt', render: dto => formatDate(dto.lastModifiedAt), editable: 'never', hidden: true },
        { title: 'Created by', field: 'createdBy', editable: 'never', hidden: true },
    ];


    const handleCreate = (newData: ServiceOperationDto): Promise<void> =>
        new Promise((resolve, reject) => {

            const validationResult = validateContract(newData);
            if (!validationResult.isValid) {
                notify({ message: validationResult.helperText ?? '', severity: 'error' });
                return reject(validationResult.helperText);
            }

            addService({
                deviceId: deviceDetail?.id ?? '',
                service: {
                    subject: newData.subject ?? '',
                    state: newData.state,
                    order: newData.order,
                    createdAt: newData.createdAt,
                    acceptedAt: newData.acceptedAt,
                    handedOutAt: newData.handedOutAt,
                }
            });

            resolve();
        });

    const handleUpdate = (newData: ServiceOperationDto, oldData?: ServiceOperationDto): Promise<void> =>
        new Promise((resolve, reject) => {

            const validationResult = validateContract(newData);
            if (!validationResult.isValid) {
                notify({ message: validationResult.helperText ?? '', severity: 'error' });
                return reject(validationResult.helperText);
            }

            updateService({
                deviceId: deviceDetail?.id ?? '',
                serviceId: oldData?.id ?? '',
                service: { ...newData }
            });

            resolve();
        });

    const handleDelete = (oldData: ServiceOperationDto): Promise<void> =>
        new Promise((resolve, reject) => {
            deleteService({
                deviceId: deviceDetail?.id ?? '',
                serviceId: oldData?.id ?? ''
            });
            resolve();
        });

    return (
        <div className={classes.root}>
            <MaterialTable

                title="Service"
                isLoading={isLoading}
                columns={columns}
                data={deviceDetail?.serviceOperations ?? []}
                options={{
                    paging: false,
                    addRowPosition: 'first',
                }}

                editable={{
                    onRowAdd: handleCreate,
                    onRowUpdate: handleUpdate,
                    onRowDelete: handleDelete,
                    isEditable: rowData => isOwner(rowData),
                    isDeletable: rowData => isAdmin,
                }}

                actions={[]}

                detailPanel={[
                    rowData => ({
                        tooltip: 'Service Details',
                        icon: () => (<DetailsSharp color='primary' />),
                        render: (rowData) => (
                            <ServiceDetailCard
                                key={rowData.id}
                                readOnly={!isOwner(rowData)}
                                deviceId={deviceDetail?.id ?? ''}
                                detail={rowData}
                            />
                        ),
                    }),
                ]}
            />

        </div>
    )
}

export default ServiceCard;