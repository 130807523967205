import React, { useEffect } from 'react';

// store
import { useStoreActions, useStoreState } from '../../hooks';

// mui
import { Card, CardContent, CardHeader, createStyles, makeStyles, Theme } from '@material-ui/core';

// utils
import DriveManagerCard from '../device/DriveManagerCard';
import ChecklistCard from '../device/ChecklistCard';
import NoteCard from '../NoteCard';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface Props {
}

interface State {
}


export const Test: React.FC<Props> = props => {

  const classes = useStyles();

  return (
    <div className={classes.root} >
{/* 
      <NoteCard
        text={'ahoj vlevo'}
        readOnly={false}
        onSave={note => { }}
      />

      <DriveManagerCard
        deviceId='7faa65e0-33b3-404b-af0f-661a93fe7b3f'
        serviceId='3fb27e0b-57aa-46ce-bf7c-9497ad75d0a8'
        readOnly={false}
      />

      <ChecklistCard
        key={'7faa65e0-33b3-404b-af0f-661a93fe7b3f'}
        readOnly={false}
        checklist={undefined}
        onSave={checklist => { }}
      />

      <Card>
        <CardHeader title="Test" />
        <CardContent>
          <div color='pink'>
            <p>Test</p>
          </div>
        </CardContent>
      </Card> */}

    </div>
  );
}

export default Test;