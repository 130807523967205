import React from 'react';

// store
import { useStoreActions, useStoreState, } from '../../hooks'

// mui
import { Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';


// pdf
import { pdf, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Style as PdfStyle } from '@react-pdf/types';

// dto
import { ComponentCategoryValue, ComponentDto, DeviceDto } from '../../service/dataContract';

// utils
import { formatDate } from '../../utils/datetime'
import { componentCategoryLookup, getLookupKey, resolveCountry } from '../../model/codelist';
import { enumerateComponentParams  } from '../../utils/componnetParametrs';
import authStore from '../../store/authStore';

const styles = StyleSheet.create({

  page: {
    flexDirection: 'column',
    width: '100%',
    fontSize: 10,
    marginTop: 15,
  },
  sectionTitle: {
    fontSize: 12,
    marginBottom: 3,
    borderBottom: 0.25,
  },
  section: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
  },
  rowSection: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
    flexDirection: 'row',
  },

  // info header
  headerImage: {
    position: 'absolute',
    width: 80,
    marginLeft: 480,
    marginTop: 30,
  },
  headerRow: {
    flexDirection: 'row',
  },
  headerRowTitle: {
    width: 100,
  },
  headerRowValue: {
    width: '100%',
  },

  // component header
  componentRow: {
  },
  componentRowTitle: {
  },
  componentRowValue: {
  },

});



interface HeaderRowProps {
  title: string,
  value?: string,
  rowStyle: PdfStyle,
  titleStyle: PdfStyle,
  valueStyle: PdfStyle,
}

const HeaderRow: React.FC<HeaderRowProps> = ({ title, value, titleStyle, valueStyle, rowStyle }) => {
  return (
    <View style={rowStyle}>
      <Text style={titleStyle}>{title}</Text>
      <Text style={valueStyle}>{value}</Text>
    </View>
  )
}


interface ComponentRowProps {
  component: ComponentDto,  
  rowStyle: PdfStyle,
  titleStyle: PdfStyle,
  valueStyle: PdfStyle,
}

const ComponentRow: React.FC<ComponentRowProps> = ({ component, titleStyle, valueStyle, rowStyle }) => {

  function buildComponentDesc(component: ComponentDto): TitleValue {

    const protoParams = enumerateComponentParams(component, {includeVgn: true});

    let parts: string[] = [];
    protoParams.map(param => {
      const part = param.keyName
        ? `${param.keyName}: ${param.value} ${param.keyUnit}`
        : `${param.key}: ${param.value}`;
      parts = [...parts, part];
    });

    if (parts.length === 0)
      parts.push('-');

    return {
      title: `${component.componentPrototype?.name ?? "N/A"}: `,
      value: parts.join(', ')
    }
  }

  const { title, value } = buildComponentDesc(component);

  return (
    <View style={{ flexDirection: 'row', marginBottom: 4, }}>
      <View style={{ flexDirection: 'row', width: 150 }}>
        <Text style={titleStyle}>{title}</Text>
      </View>
      <View style={{ flexDirection: 'row', width: 400 }}>
        <Text style={valueStyle}>{value}</Text>
      </View>
    </View>
  )
}


interface ReportProps {
  source: DeviceDto,
}

interface TitleValue {
  title: string,
  value: string,
}

const Report: React.FC<ReportProps> = props => {

  const missing = 'N/A';

  // auth store is used instead of authModel (there is some unexplained issue with hooks)
  const fullName = authStore.fullName() ?? missing;

  // info header
  const deviceType: TitleValue = { title: 'Device Type:', value: props.source.deviceType?.name ?? missing };
  const deviceSerial: TitleValue = { title: 'Serial Number:', value: props.source.serialNumber ?? missing };
  const opSince: TitleValue = { title: 'Operating since:', value: formatDate(props.source.operatingSince) };
  const createdAt: TitleValue = { title: 'Printed At:', value: new Date().toLocaleString() };
  const createdBy: TitleValue = { title: 'Printed By:', value: fullName };

  const infoRows = [deviceType, deviceSerial, opSince, createdAt, createdBy];


   // contact header
  const contactCompany: TitleValue = { title: 'Company:', value: props.source.contact?.companyName ?? missing };
  const contactPerson: TitleValue = { title: 'Contact:', value: props.source.contact?.personName ?? missing };
  const contactEmail: TitleValue = { title: 'Email:', value: props.source.contact?.email ?? missing };
  const contactPhone: TitleValue = { title: 'Phone:', value: props.source.contact?.phoneNumber ?? missing };
  const contactCity: TitleValue = { title: 'City:', value: props.source.contact?.city ?? missing };
  const contactStreet: TitleValue = { title: 'Street:', value: props.source.contact?.street ?? missing };
  const contactCountry: TitleValue = { title: 'Country:', value: resolveCountry(props.source.contact?.countryCode).name };

  const contactRows = [contactCompany, contactPerson, contactEmail, contactPhone, contactCity, contactStreet, contactCountry];

  function filterComponents(categoryValue: ComponentCategoryValue) {
    const key = getLookupKey(componentCategoryLookup, categoryValue);
    return props.source?.components?.filter(dc => dc.componentPrototype?.componentType?.componentCategory == key) ?? [];
  }

  const mechanicComponents = filterComponents('Mechanic');
  const electronicComponents = filterComponents('Electronic');
  const sensoricComponents = filterComponents('Sensor');
  const softwareComponents = filterComponents('Software');

  const componentGroups = [
    { title: 'Sensoric Components:', list: sensoricComponents },
    { title: 'Electronics Components:', list: electronicComponents },
    { title: 'Mechanic Components:', list: mechanicComponents },
    { title: 'Software:', list: softwareComponents },
  ];

  return (
    <Document>

      <Page size="A4" style={styles.page}>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Device Info:</Text>
          {infoRows.map(row => (
            <HeaderRow
              rowStyle={styles.headerRow}
              titleStyle={styles.headerRowTitle}
              valueStyle={styles.headerRowValue}
              title={row.title}
              value={row.value} />
          ))}
          <Image
            style={styles.headerImage}
            src="/kzv-logo.png" />
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Owner:</Text>
          {contactRows.map(row => (
            <HeaderRow
              rowStyle={styles.headerRow}
              titleStyle={styles.headerRowTitle}
              valueStyle={styles.headerRowValue}
              title={row.title}
              value={row.value} />
          ))}
        </View>


        {componentGroups.map(group => (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>{group.title}</Text>
            {group.list.map(comp => (
              <ComponentRow
                component={comp}
                rowStyle={styles.componentRow}
                titleStyle={styles.componentRowTitle}
                valueStyle={styles.componentRowValue}
              />
            ))}
          </View>
        ))}

      </Page>
    </Document >
  );
}


export const DeviceDetailPrint = () => {

  const { deviceDetail } = useStoreState(state => state.device);
  const { notify } = useStoreActions(state => state.audit);

  function handlePrint() {

    // test loaded
    if (!deviceDetail) {
      notify({ severity: 'error', message: 'Can not build report, device info is not loaded.' });
      return;
    }

    const now = new Date();
    const fileName = `${deviceDetail.deviceType?.name}-${deviceDetail.serialNumber}_${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}.pdf`;

    pdf(<Report source={deviceDetail} />)
      .toBlob()
      .then(blob => {
        let tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.setAttribute('download', fileName);
        tempLink.click();
        notify({ severity: 'info', message: 'Report is downloading.' });
      })
      .catch(err => {
        notify({ severity: 'error', message: 'can not build report.', payload: err });
      });
  }

  return (
    <div>
      {deviceDetail && (
        <Button
          color='primary'
          variant='contained'
          startIcon={<DescriptionIcon />}
          onClick={(event) => {
            event.stopPropagation();
            handlePrint();
          }}>
          Create Report
        </Button>
      )}
    </div>
  )
}

export default DeviceDetailPrint;