import React, { useRef, useState } from 'react';

// mui
import { Box, Button, Card, CardActions, CardContent, FormControl, Input, InputAdornment, InputLabel, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';

// dtos
import { AttachFile } from '@material-ui/icons';
import { useStoreActions, useStoreState } from '../../hooks';
import { Country } from '../../model/codelist';
import { LicenseBriefDto, CreateLicenseDto, LicenseType } from '../../service/dataContract';
import CountryInput from '../CountryInput';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background: theme.palette.background.default,
        },
        cardActions: {
            display: "flex",
            justifyContent: "flex-end",
            background: theme.palette.background.default,
        },
        formControl: {
            margin: theme.spacing(1),
            width: '100%',
            minWidth: '360',
        },
    }),
);

interface Props {
    data: LicenseBriefDto
}

interface State {
    notes: string;
    internalDescription: string;
    fileName: string;
    licenseFile?: File;
    ownerName: string,
    ownerCountryCode: string,
    ownerCountry: string,
    changed: boolean,
}

export const FileLicenseCard: React.FC<Props> = (props) => {

    const classes = useStyles();
    const fileRef = useRef<HTMLInputElement>(null);

    const { updateLicense } = useStoreActions(state => state.license);
    const { isKzv, isSalesRep } = useStoreState(state => state.auth);

    const [state, setState] = useState<State>({
        notes: props.data.notes ?? '',
        internalDescription: props.data.internalDescription?? '',
        fileName: props.data.fileName ?? '',
        ownerName: props.data.ownerName ?? '',
        ownerCountry: props.data.ownerCountry?? '',
        ownerCountryCode: props.data.ownerCountryCode ?? '',
        changed: false,
    });

    const isDisabled = () => isSalesRep;

    const handleStateChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [prop]: event.target.value, changed: true })
    }

    const handleChoseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = (event.target.files ?? [])[0];
        if (selectedFile) {
            event.target.value = '';
            setState({ ...state, licenseFile: selectedFile, fileName: selectedFile.name, changed: true });
        }
    }

    const handleCountryChange = (country: Country | null) => {
        setState({ ...state, ownerCountryCode: country?.code ?? '', changed: true })
    };

    const handleUpdateLicense = () => {

        updateLicense({
            licenseId: props.data.id,
            license: { 
                id: '',
                version: 'file', 
                licenseType: LicenseType.File,           

                allowedLanguages: [],
                allowedRegions: [],
                allowedVgnBoards: [],

                moduleValues: [],     
                ...state }
        });

        setState({ ...state, changed: false });
    }

    return (
        <Card>
            <CardContent className={classes.cardContent}>

                <FormControl className={classes.formControl}>
                    <input
                        style={{ display: 'none' }}
                        name="upload-file"
                        type="file"
                        ref={fileRef}
                        disabled={isDisabled()}
                        onChange={handleChoseFile}
                    />

                    <InputLabel shrink>License File</InputLabel>
                    <Input
                        type='text'
                        disabled={isDisabled()}
                        onClick={() => { fileRef?.current?.click() }}
                        value={state.fileName}
                        endAdornment={
                            <InputAdornment position='end'>
                                <AttachFile />
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <Box display="flex" flexDirection="row">

                    <FormControl className={classes.formControl}>
                        <TextField
                            label='Owner Name'
                            value={state.ownerName}
                            fullWidth
                            onChange={handleStateChange('ownerName')}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ readOnly: isDisabled() }}
                        />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <CountryInput
                            label='Owner Country'
                            disabled={isDisabled()}
                            isoCode={state.ownerCountryCode}
                            onChange={handleCountryChange}
                        />
                    </FormControl>
                </Box>

                <FormControl className={classes.formControl}>
                    <TextField
                        fullWidth
                        label='Notes'
                        variant='standard'
                        value={state.notes}
                        onChange={handleStateChange('notes')}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ readOnly: isDisabled() }}
                    />
                </FormControl>

                <FormControl className={classes.formControl}>
                    <TextField
                        fullWidth
                        label='Internal Notes (service web only)'
                        variant='standard'
                        value={state.internalDescription}
                        onChange={handleStateChange('internalDescription')}
                        InputLabelProps={{ shrink: true }}
                         inputProps={{ readOnly: isDisabled() }}
                    />
                </FormControl>

            </CardContent >

            <CardActions className={classes.cardActions}>
                {(isKzv &&
                    <Box display="flex" flexDirection="row">
                        <Button
                            autoFocus
                            color='secondary'
                            startIcon={<DoneIcon />}
                            disabled={!state.changed}
                            onClick={handleUpdateLicense}>
                            Save
                        </Button>
                    </Box>
                )}
            </CardActions>

        </Card >
    )
}

export default FileLicenseCard;