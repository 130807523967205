import  {ValidationResult} from './validations';

/* constants */
// email rx pattern
export const emailRxPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// semantic versioning rx pattern
export const semverRxPattern: RegExp = /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/;

export const nameRxPattern: RegExp = /^.{4,}[^.]$/;

// text validation
export const validateInput = (text: any, minLength: number, help?: string): ValidationResult => {
    if (!text || text.length < minLength)
        return {
            isValid: false,
            helperText: help ?? `use at least ${minLength} chars...`
        }
    else {
        return { isValid: true }
    }
}

// input / lookup validation
export const validateDefined = (value: any | undefined, help?: string): ValidationResult => {
    if (!value || value === '0')
        return {
            isValid: false,
            helperText: help ?? `select...`
        }
    else {
        return { isValid: true }
    }
}
/*
// input / lookup validation
export const validateNotIn = (value: any | undefined, data: any[], help?: string): ValidationResult => {
    if (data.includes(value))
        return {
            isValid: false,
            helperText: help ?? `select one...`
        }
    else {
        return { isValid: true }
    }
}
*/

// email validation
export const validateEmail = (email: any, help?: string): ValidationResult => {
    if (!email || !emailRxPattern.test(email ?? ''))
        return {
            isValid: false,
            helperText: help ?? `use a valid email...`
        }
    else {
        return { isValid: true }
    }
}

export const validateRx = (value: any, pattern: RegExp, help: string): ValidationResult => {
    if (!value || !pattern.test(value ?? ''))
        return {
            isValid: false,
            helperText: help,
        }
    else {
        return { isValid: true }
    }
}

// validate password
export const validatePassword = (password: any, help?: string): ValidationResult => {
    
    const min = 5;

    if (!password || password.length < min)
        return {
            isValid: false,
            helperText: help ?? `use at least ${min} chars...`
        }
    else {
        return { isValid: true }
    }
}