import { Thunk, thunk } from 'easy-peasy';
import { StoreContext } from '.';
import store, { Injections } from '../store';

// dto
import { CreateServiceOperationDto, ServiceOperationDto, DeviceDto, DriveItemsQueryDto, DriveItemDto } from '../service/dataContract';
import { Progress } from '../service/httpClient';
import { base64toBlob } from '../utils/fileUtils';

// device store interface
export interface ServiceState {

    // create service record
    addService: Thunk<ServiceState, { deviceId: string, service: CreateServiceOperationDto }, Injections, StoreContext>,

    // update existing service record
    updateService: Thunk<ServiceState, { deviceId: string, serviceId: string, service: CreateServiceOperationDto }, Injections, StoreContext>,

    // delete existing service record
    deleteService: Thunk<ServiceState, { deviceId: string, serviceId: string }, Injections, StoreContext>,

    // fetch files for a service
    fetchDriveItems: Thunk<ServiceState, { deviceId: string, serviceId: string, query: DriveItemsQueryDto }, Injections, StoreContext, Promise<DriveItemDto[]>>,

    uploadDriveItem: Thunk<ServiceState, { deviceId: string, serviceId: string, formData: FormData, uploadProgress: Progress }, Injections, StoreContext, Promise<DriveItemDto>>,
    downloadDriveItem: Thunk<ServiceState, { deviceId: string, serviceId: string, path: string, downloadProgress: Progress }, Injections, StoreContext>,
    deleteDriveItem: Thunk<ServiceState, { deviceId: string, serviceId: string, path: string }, Injections, StoreContext, Promise<void>>,
};

const serviceState: ServiceState = {

    addService: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;
        getStoreActions().device.setLoading(true);

        return await serviceClient.create(payload)
            .then(sop => {
                getStoreActions().device.storeServiceOperation({ mode: 'insert', serviceOp: sop })
                getStoreActions().audit.notify({ severity: 'info', message: `Service has been sucessfully created.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create service: ${payload.service.subject}.`, payload: err })
            })
            .finally(() => {
                getStoreActions().device.setLoading(false);
            });
    }),

    updateService: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;
        getStoreActions().device.setLoading(true);

        return await serviceClient.update(payload)
            .then(sop => {
                getStoreActions().device.storeServiceOperation({ mode: 'update', serviceOp: sop })
                getStoreActions().audit.notify({ severity: 'info', message: `Service has been sucessfully updated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update service: ${payload.service.subject}.`, payload: err })
            })
            .finally(() => {
                getStoreActions().device.setLoading(false);
            });
    }),

    deleteService: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { serviceClient } = injections;
        getStoreActions().device.setLoading(true);

        return await serviceClient.delete(payload)
            .then(_ => {
                getStoreActions().device.storeServiceOperation({ mode: 'delete', serviceOpId: payload.serviceId })
                getStoreActions().audit.notify({ severity: 'info', message: `Service has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete service.`, payload: err })
            })
            .finally(() => {
                getStoreActions().device.setLoading(false);
            });
    }),

    fetchDriveItems: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;

        return await serviceClient.fetchDriveItems(payload)
            .then(driveItems => {
                return Promise.resolve(driveItems);
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unabe to get drive files.`, payload: err })
                return Promise.reject();
            });
    }),

    uploadDriveItem: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;

        return await serviceClient.uploadDriveItem(payload)
            .then(file => {
                getStoreActions().audit.notify({ severity: 'info', message: `File has been sucessfully uploaded.` })
                return Promise.resolve(file);
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unabe to uplaod file.`, payload: err });
                return Promise.reject();
            })
    }),

    downloadDriveItem: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;

        await serviceClient.downloadDriveItem(payload)
            .then(file => {

                // get data (extract file name, convert blob from base64 to utf8)
                const fname = file.name ?? 'sw_downloaded_file.unknown';
                const blob = base64toBlob(file.content ?? '', 'application/octet-stream');
                
                // create download link
                let tempLink = document.createElement('a');
                tempLink.href = window.URL.createObjectURL(blob);
                tempLink.setAttribute('download', fname);
                tempLink.click();
                tempLink.remove();

                getStoreActions().audit.notify({ severity: 'info', message: `Downloading file has been initiated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Download file error.`, payload: err });
            });

    }),

    deleteDriveItem: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { serviceClient } = injections;

        return await serviceClient.deleteDriveItem(payload)
            .then(() => {
                getStoreActions().audit.notify({ severity: 'info', message: `File has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete file.`, payload: err });
            });
    }),
}

export default serviceState;
