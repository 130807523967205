import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

// store
import { useStoreState, useStoreActions } from '../../hooks'
import routes from '../../routes';

// mui
import { Box, Button, Fab, IconButton, Toolbar, Tooltip } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';

// components
import ConfirmDialog from '../ConfirmDialog';
import { EntityState } from '../../service/dataContract';

const useStyles = makeStyles((theme: Theme) => createStyles({
    toolbar: {
        padding: theme.spacing(0),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'right',
    },
    navButton: {
    },
    toolButton: {
        margin: theme.spacing(0, 0, 0, 2),
    }
}));

interface Props {
}

export const DeviceToolbar: React.FC<Props> = props => {

    const classes = useStyles();
    let history = useHistory();

    const { deviceDetail } = useStoreState(state => state.device);
    const { deleteDevice, restoreDevice } = useStoreActions(state => state.device);
    const { isKzv, isAdmin } = useStoreState(state => state.auth);

    const isActive = () => deviceDetail?.systemState === EntityState.Active;

    const [deleteDialogOpen, setDeleteDevOpen] = React.useState(false);

    const handleDeviceDelete = () => {
        deleteDevice({ deviceId: deviceDetail?.id ?? '' })
            .then(() => {
                history.push(routes.home);
            })
    }

    const handleDeviceRestore = () => {
        restoreDevice({ deviceId: deviceDetail?.id ?? '' })
            .then(() => {
                window.location.reload();
            })
    }

    return (
        <Toolbar className={classes.toolbar}>

            <ConfirmDialog
                isOpen={deleteDialogOpen}
                title='Delete Device'
                message='Do you really want to (SOFT) delete this device?'
                onCancel={() => { setDeleteDevOpen(false) }}
                onConfirm={() => { handleDeviceDelete() }}>
            </ConfirmDialog>

            <Box alignContent='right' alignSelf='right' flexGrow={1} display='flex' flexDirection='row-reverse'>

                {isKzv && (
                    <Button
                        className={classes.toolButton}
                        variant='contained'
                        color='primary'
                        startIcon={<ControlPointDuplicateIcon />}
                        onClick={() => { history.push(routes.deviceClone.formatPath(deviceDetail?.id ?? '')) }}>
                        Clone
                    </Button>)}

                {isAdmin && isActive() && (
                    <Button
                        className={classes.toolButton}
                        variant='contained'
                        color='secondary'
                        startIcon={<DeleteIcon />}
                        onClick={() => { setDeleteDevOpen(true) }}>
                        Discard
                    </Button>)}

                {isAdmin && !isActive && (
                    <Button
                        className={classes.toolButton}
                        variant='contained'
                        color='secondary'
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeviceRestore()}>
                        Restore
                    </Button>)}

            </Box>
        </Toolbar >
    )
}

export default DeviceToolbar;