import React, { useEffect, useState } from 'react';

// store
import { useStoreState, useStoreActions } from '../../hooks'

// mui
import { List, Paper, Box, Typography, IconButton, ListItemSecondaryAction, Button, Card, CardHeader, CardContent, Avatar, Badge } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import FolderIcon from '@material-ui/icons/Folder';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import StorageIcon from '@material-ui/icons/Storage';
import DeleteIcon from '@material-ui/icons/Delete';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';

// dialogs
import ConfirmDialog from '../ConfirmDialog';

// dtos
import { DriveItemDto } from '../../service/dataContract';
import { set } from 'lodash';

// utils

// styles
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    panel: {
        width: '100%',
        marginRight: theme.spacing(1),
        maxHeight: 300,
        overflowY: 'auto'
    },
    dropzoneRoot: {
        height: '100%',
    },
    badge: {
    },
}));

// props with expected route params
interface Props {
    readOnly: boolean,
    deviceId: string,
    serviceId: string,
}

interface FileDto extends DriveItemDto {
    downloadProgress?: number;
}

interface FileUploadDto {
    file?: File;
    folder: string;
    uploadProgress: number;
    dislplayMode: 'determinate' | 'indeterminate';
    uploaded: boolean,
}

// device service tool
export const DriveManagerCard: React.FC<Props> = props => {

    const classes = useStyles();

    const { fetchDriveItems, downloadDriveItem, deleteDriveItem, uploadDriveItem } = useStoreActions(state => state.service);
    const { notify } = useStoreActions(state => state.audit);

    const [isFoldersLoading, setIsFoldersLoading] = useState<boolean>(false);
    const [listedFolders, setListedFolders] = useState<DriveItemDto[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<DriveItemDto>({});
    const [isFilesLoading, setIsFilesLoading] = useState<boolean>(false);
    const [listedFiles, setListedFiles] = useState<FileDto[]>([]);
    const [lisetdUploads, setListedUploads] = useState<FileUploadDto[]>([]);

    const [deleteDialogState, setDeleteDialogState] = React.useState<{ isOpen: boolean, file?: DriveItemDto }>({ isOpen: false });

    // fetch root drive items
    useEffect(() => {

        async function fetchData() {

            setIsFoldersLoading(true);

            await fetchDriveItems({
                deviceId: props.deviceId,
                serviceId: props.serviceId,
                query: { folder: '' }
            }).then((driveItems: DriveItemDto[]) => {
                setListedFolders([...driveItems]);
                setSelectedFolder({});
            }).catch(err => {
                setListedFolders([]);
            }).finally(() => {
                setIsFoldersLoading(false)
            });
        }

        fetchData();

    }, [props.serviceId]);

    // fetch files
    useEffect(() => {
        async function fetchData() {

            setIsFilesLoading(true);

            await fetchDriveItems({
                deviceId: props.deviceId,
                serviceId: props.serviceId,
                query: { folder: selectedFolder.name ?? '' }
            }).then((driveItems: DriveItemDto[]) => {
                setListedFiles([...driveItems.map(item => ({ ...item }))]);
            }).catch(err => {
                setListedFiles([]);
            }).finally(() => setIsFilesLoading(false));
        }

        if (selectedFolder?.name) {
            fetchData();
        }

    }, [selectedFolder]);

    const formatFileSize = (size?: number) => {

        if (!size)
            return 'n/a'

        if (size < 1024) return `${size} bytes`;
        if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;
        if (size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(1)} MB`;
        return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
    };

    const handleOpenDirectory = (driveItem: DriveItemDto) => {
        setSelectedFolder(driveItem);
    };

    const isDownloading = (driveItem: FileDto): boolean => {
        if (driveItem.downloadProgress) {
            return driveItem.downloadProgress > 0 && driveItem.downloadProgress < 100;
        }
        return false;
    }

    const isFolderSelected = (): boolean => {
        return selectedFolder.name !== undefined;
    }

    const updateFolderChilds = (folderName: string, increment: number) => {
        setListedFolders(items => items.map(item => {
            if (item.name === folderName) {
                item.childCount = (item.childCount ?? 0) + increment;
                if (item.childCount < 0) {
                    item.childCount = 0;
                }
            }
            return item;
        }));
    }

    const handleDownloadFile = (driveItem: DriveItemDto) => {

        const updateProgress = (value: number) => {
            var update = listedFiles.find(uf => uf.path == driveItem.path);
            if (update) {
                update.downloadProgress = value;
                setListedFiles([...listedFiles]);
            }
        };

        updateProgress(1);

        downloadDriveItem({
            deviceId: props.deviceId,
            serviceId: props.serviceId,
            path: driveItem.path ?? '',
            downloadProgress: (event: ProgressEvent) => {
                updateProgress((event.loaded / event.total) * 100);
            }
        });
    };

    const handleDeleteFile = (driveItem: DriveItemDto) => {

        setListedFiles(listedFiles.filter(item => item.path !== driveItem.path));
        updateFolderChilds(selectedFolder.name ?? '', -1);

        deleteDriveItem({
            deviceId: props.deviceId,
            serviceId: props.serviceId,
            path: driveItem.path ?? ''
        }).finally(() => {

        })
    };

    const handleIncomingFiles = (fileObjects: FileObject[]) => {

        console.log("Uploading: ", fileObjects);

        const uploadFolder = selectedFolder.name ?? '';
        const uploadFiles = fileObjects.map<FileUploadDto>(fo => ({
            file: fo.file,
            folder: selectedFolder.name ?? '',
            fileSize: fo.file.size,
            uploadProgress: 0,
            dislplayMode: 'determinate',
            uploaded: false,
        }));

        setListedUploads(items => items.concat([...uploadFiles]));

        uploadFiles.forEach(async item => {

            const formData = new FormData();
            formData.append('file', item.file as File, item.file?.name ?? '');
            formData.append('folder', selectedFolder.name ?? '');

            await uploadDriveItem({
                deviceId: props.deviceId,
                serviceId: props.serviceId,
                formData: formData,
                uploadProgress: (event: ProgressEvent) => {

                    setListedUploads(items => {
                        const updatedProgress = items.map<FileUploadDto>(uf => {
                            if (uf.file === item.file) {
                                const progress = (event.loaded / event.total) * 100;
                                return { ...uf, uploadProgress: progress, dislplayMode: (progress < 95) ? 'determinate' : 'indeterminate', };
                            }
                            return uf;
                        });
                        return updatedProgress;
                    });

                }
            }).then(file => {

                // filter out all finished uploads
                setListedUploads(items => items.filter(item => item.file?.name !== file.name));

                // add new uploaded file to list if not already there
                if (!listedFiles.find(f => f.path == file.path)) {
                    setListedFiles(items => [...items, { ...file, downloadProgress: 0 }]);
                }

                updateFolderChilds(uploadFolder, +1);

            }).catch(err => {
                notify({ severity: 'error', message: 'Unable to upload file', payload: err });
                setListedUploads(items => items.filter(uf => uf.file?.name !== item.file?.name));
            });
        });
    }

    return (
        <div className={classes.root}>

            <ConfirmDialog
                isOpen={deleteDialogState.isOpen}
                title='Delete File?'
                message={`Do you want to delete the file: ${deleteDialogState.file?.name}?`}
                onCancel={() => {
                    setDeleteDialogState({ isOpen: false })
                }}
                onConfirm={() => {
                    if (deleteDialogState.file) {
                        handleDeleteFile(deleteDialogState.file);
                    }
                    setDeleteDialogState({ isOpen: false })
                }}>
            </ConfirmDialog>

            <Card>
                <CardHeader
                    title={
                        <Typography variant='body1'>Drive Manager</Typography>
                    }
                    avatar={
                        <Avatar variant='rounded'>
                            <StorageIcon />
                        </Avatar>
                    }>
                </CardHeader>

                <CardContent>
                    <Box display='flex' flexDirection='row'>

                        <Paper className={classes.panel}>
                            {isFoldersLoading && (<CircularProgress />)}
                            {!isFoldersLoading && (

                                <List dense>
                                    {listedFolders.map((item, index) => (
                                        <ListItem key={item.path} button onClick={() => handleOpenDirectory(item)} selected={selectedFolder.path === item.path}>
                                            <ListItemIcon>
                                                {item.isFolder ? <Badge className={classes.badge} variant='standard' color='secondary' badgeContent={item.childCount} max={20} >  <FolderIcon color='primary' /> </Badge> : <AttachmentIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </Paper>

                        <Paper className={classes.panel}>
                            {isFilesLoading && (<CircularProgress />)}
                            {!isFilesLoading && (
                                <List dense>

                                    {listedFiles.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0).map((item, index) => (
                                        <ListItem key={item.path} button={false}>

                                            {(!props.readOnly && !isDownloading(item)) && (
                                                <IconButton onClick={() => setDeleteDialogState({ isOpen: true, file: item })} color='secondary' size='small' >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}

                                            <ListItemText primary={item.name} />

                                            <ListItemSecondaryAction>

                                                <Typography variant='body2' component='span' color='secondary'>{formatFileSize(item.fileSize)}</Typography>

                                                {isDownloading(item) && (
                                                    <CircularProgress
                                                        variant='indeterminate'
                                                        color='secondary'
                                                        size={30}
                                                    />)}

                                                {!isDownloading(item) && (
                                                    <IconButton onClick={() => handleDownloadFile(item)} color='primary' size='small'>
                                                        <CloudDownloadIcon />
                                                    </IconButton>
                                                )}

                                            </ListItemSecondaryAction>

                                        </ListItem>
                                    ))}


                                    {lisetdUploads.filter(uf => uf.folder === selectedFolder.name).map((item, index) => (
                                        <ListItem key={item.file?.name} button={false}>

                                            <ListItemText primary={item.file?.name} />

                                            <ListItemSecondaryAction>

                                                <Typography variant='body2' component='span' color='secondary'>{formatFileSize(item.file?.size)}</Typography>

                                                <CircularProgress
                                                    variant={item.dislplayMode}
                                                    value={item.uploadProgress}
                                                    color='secondary'
                                                    size={30}
                                                />

                                            </ListItemSecondaryAction>

                                        </ListItem>
                                    ))}

                                </List>
                            )}

                        </Paper>

                        <Paper className={classes.panel}>
                            <DropzoneAreaBase
                                fileObjects={[]}
                                filesLimit={10}
                                dropzoneProps={{ disabled: !isFolderSelected() }}
                                maxFileSize={300 * 1024 * 1024}
                                dropzoneText={isFolderSelected() ? 'Drop files or click' : 'Select folder first'}
                                onAdd={handleIncomingFiles}
                                showAlerts={['error']}
                                classes={{
                                    root: classes.dropzoneRoot,
                                }}
                            />
                        </Paper>

                    </Box>
                </CardContent>
            </Card>

        </div >
    )
}

export default DriveManagerCard;