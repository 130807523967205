import React, { useEffect, useState } from 'react';

// store
import { useStoreState, useStoreActions } from '../../hooks'

// mui
import { Badge, List, Paper, Card, CardContent, CardHeader, Box, Typography, IconButton, ListItemSecondaryAction, Button } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';

import FolderIcon from '@material-ui/icons/Folder';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { CloudCircleRounded } from '@material-ui/icons';

// components
import NoteCard from '../NoteCard';

// dtos
import { ServiceOperationDto, DriveItemDto, ChecklistPayloadDto } from '../../service/dataContract';

// utils
import ChecklistCard from './ChecklistCard';
import DriveManagerCard from './DriveManagerCard';


// styles
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#EEEE',
        padding: theme.spacing(0),
        margin: theme.spacing(1),
    },
   
}));

// props with expected route params
interface Props {
    readOnly: boolean,
    deviceId: string,
    detail: ServiceOperationDto,
}

interface State {
    readOnly: boolean,
    changed: boolean,
    note: string,
    checklist: ChecklistPayloadDto,
}

// device service tool
export const ServiceDetailCard: React.FC<Props> = props => {

    const classes = useStyles();

    const { updateService } = useStoreActions(state => state.service);

    const [values, setValues] = useState<State>({
        readOnly: props.readOnly,
        changed: false,
        note: props.detail.note ?? '',
        checklist: props.detail.checklist ?? {},
    });

    useEffect(() => {
        setValues({ ...values, note: props.detail.note ?? '' });
    }, [props.detail.note]);

    const handleUpdateNote = (data: ServiceOperationDto, note: string): Promise<void> =>
        new Promise((resolve, reject) => {

            setValues({ ...values, note: note });

            updateService({
                deviceId: props.deviceId,
                serviceId: data?.id ?? '',
                service: { note }
            });
            resolve();
        });

    const handleUpdateCheckList = (data: ServiceOperationDto, checklist: ChecklistPayloadDto): Promise<void> =>
        new Promise((resolve, reject) => {

            setValues({ ...values, checklist: checklist });

            updateService({
                deviceId: props.deviceId,
                serviceId: data?.id ?? '',
                service: { checklist: checklist }
            });
            resolve();
        });

    return (
        <div className={classes.root}>

            <NoteCard
                text={values.note}
                readOnly={props.readOnly}
                onSave={note => handleUpdateNote(props.detail, note)}
            />

            <DriveManagerCard
                deviceId={props.deviceId}
                serviceId={props.detail.id}
                readOnly={props.readOnly} />

            <ChecklistCard
                key={props.detail.id}
                readOnly={props.readOnly}
                checklist={props.detail.checklist}
                onSave={checklist => handleUpdateCheckList(props.detail, checklist)}
            />

        </div >
    )
}

export default ServiceDetailCard;