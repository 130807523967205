import { Action, action, Thunk, thunk } from 'easy-peasy';
import { StoreContext } from '.';
import { Injections } from '../store';
import { VgnDto } from '../service/dataContract';
import { Progress } from '../service/httpClient';

// device store interface
export interface VgnModel {

    // loading indnpm startication
    isLoading: boolean,
    isFetched: boolean,
    vgnBoards: VgnDto[],

    // fetch all vgn devices
    fetchVgn: Thunk<VgnModel, void, Injections, StoreContext, Promise<void>>,

    // creates a new device
    importVgn: Thunk<VgnModel, { formData: FormData, uploadProgress: Progress }, Injections, StoreContext, Promise<void>>,

    storeVgnBoards: Action<VgnModel, { boards: VgnDto[] }>,

    // set loading flag action
    setLoading: Action<VgnModel, boolean>,
    setFetched: Action<VgnModel, boolean>,
};

const vgnModel: VgnModel = {

    /* data */

    isLoading: false,
    isFetched: false,
    vgnBoards: [],

    /* thunks */

    // fetch

    fetchVgn: thunk(async (actions, payload, { injections, getStoreActions, getState }) => {


        const { vgnClient } = injections;

        if (getState().isFetched) {
            return;
        }

        actions.setLoading(true);

        await vgnClient.fetch()
            .then(boards => {

                const updatedBoards = [
                    { id: "", deviceName: "Any", serialNumber: "*", requiredAuth: "None" },
                    ...boards,
                ];

                actions.storeVgnBoards({ boards: updatedBoards });
                actions.setFetched(true);
            })
            .catch(err => {

                getStoreActions().audit.notify({
                    severity: "error",
                    message: "Unable to load list of VGN devices.",
                    payload: err,
                });

                actions.setFetched(false);

                return Promise.reject({ severity: 'error', message: `Unable to load list of VGN devices.`, payload: err });

            })
            .finally(() => {
                actions.setLoading(false);
            });

    }),

    // import

    importVgn: thunk(async (actions, payload, { injections, getStoreActions }) => {

        actions.setLoading(true);

        const { vgnClient } = injections;

        await vgnClient.import(payload)
            .then(boards => {
                
                actions.storeVgnBoards({ boards: boards });

                getStoreActions().audit.notify({
                    severity: 'info',
                    message: `VGN database has been sucessfully uploaded.`
                })
            })
            .catch(err => {

                getStoreActions().audit.notify({
                    severity: 'error',
                    message: `Can not uplaod VGN database file.`,
                    payload: err
                });

                return Promise.reject({ severity: 'error', message: `Can not uplaod VGN database file.`, payload: err });
            })
            .finally(() => {
                actions.setLoading(false);
                actions.setFetched(false);
            });
    }),

    /* actions */

    setLoading: action((store, isLoading) => { store.isLoading = isLoading; }),

    setFetched: action((store, isFetched) => { store.isFetched = isFetched; }),

    storeVgnBoards: action((store, payload) => { store.vgnBoards = payload.boards; }),

}

export default vgnModel;
