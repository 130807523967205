import React, { useEffect, useState } from 'react';

// markdown
import ReactMarkdown from 'react-markdown';

// mui
import { Avatar, Card, CardActions, CardContent, CardHeader, IconButton, InputBase, Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import HelpIcon from '@material-ui/icons/Help';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { th } from 'date-fns/locale';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
        },
        textField: {
            width: '100%',
            height: '100%',
            margin: theme.spacing(0),
            flexGrow: 1,
            background: theme.palette.grey[50],
            minHeight: 40,
        }
    }),
);

interface State {
    readOnly: boolean,
    preview: boolean,
    changed: boolean,
    text: string,
}

export interface Props {
    readOnly: boolean,
    title?: string,
    text?: string,
    onSave?: (text: string) => void,
}

export const NoteCard: React.FC<Props> = (props) => {

    const classes = useStyles();

    const [values, setValues] = useState<State>({
        readOnly: props.readOnly,
        preview: true,
        changed: false,
        text: props.text ?? ''
    });

    useEffect(() => {
        setValues({ ...values, text: props.text ?? values.text });
    }, [props])

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value, changed: event.target.value !== props.text });
    };

    const handleValueChange = (prop: keyof State, value: any) => {
        setValues({ ...values, [prop]: value });
    };

    const setPreview = () => {
        handleValueChange('preview', true);
    }

    const setEdit = () => {
        if (!props.readOnly) {
            handleValueChange('preview', false);
        }
    }

    const handleCancel = () => {
        setValues({ ...values, changed: false, text: props.text ?? '', preview: true });
    }

    const handleSave = () => {
        if (props.onSave) {
            props.onSave(values.text);
        }
        setValues({ ...values, changed: false, preview: true });
    }

    const renderTitle = () => {
        return (
            <Typography variant='body1' component={'span'}>Notes</Typography>
        )
    }

    const renderAvatar = () => {
        return (
            <Avatar variant='rounded'>
                <CommentIcon />
            </Avatar>
        )
    }

    const renderActions = () => {
        return (
            <>
                {values.preview && (
                    <Tooltip title='Edit'>
                        <span>
                            <IconButton
                                onClick={() => setEdit()}
                                color='primary'
                                size='small'
                                disabled={props.readOnly}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}

                {!values.preview && (
                    <Tooltip title='Preview'>
                        <span>
                            <IconButton
                                onClick={() => setPreview()}
                                color='primary'
                                size='small'
                                disabled={props.readOnly}>
                                <VisibilityIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}

                {!props.readOnly && (
                    <Tooltip title='Cancel changes'>
                        <span>
                            <IconButton
                                onClick={() => handleCancel()}
                                color='primary'
                                size='small'
                                disabled={!values.changed}>
                                <ClearIcon />
                            </IconButton>
                        </span>
                    </Tooltip>

                )}

                {!props.readOnly && (

                    <Tooltip title='Save changes'>
                        <span>
                            <IconButton
                                onClick={() => handleSave()}
                                color='secondary'
                                size='small'
                                disabled={!values.changed} >
                                <DoneIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}

                <Tooltip title='Help'>
                    <span>
                        <IconButton
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                                let tempLink = document.createElement('a');
                                tempLink.href = `https://www.markdownguide.org/basic-syntax/`
                                tempLink.target = '_blank';
                                tempLink.click();
                                tempLink.remove();
                            }}
                            color='primary'
                            size='small'
                            disabled={props.readOnly}>
                            <HelpIcon />
                        </IconButton>
                    </span>
                </Tooltip>

            </>
        )
    }

    return (
        <Card>

            <CardHeader
                title={renderTitle()}
                avatar={renderAvatar()}
                action={renderActions()}>
            </CardHeader>

            <CardContent>

                <div onClick={() => setEdit()}>

                    {!values.preview && (
                        <InputBase
                            className={classes.textField}
                            fullWidth
                            multiline
                            autoFocus
                            value={values.text}
                            onChange={handleChange('text')}
                            inputProps={{
                                readOnly: values.readOnly,
                                "aria-label": 'naked'
                            }}
                        />
                    )}

                    {values.preview && (
                        <ReactMarkdown
                            className={classes.textField}
                            children={values.text} />
                    )}

                </div>
            </CardContent>
        </Card>
    )
}

export default NoteCard;